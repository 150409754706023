const Slide = props => {
	return (
		<div className="slide" style={{
			width: `${props.width}%`,
			paddingLeft: `${props.gutter.left}px`,
			paddingRight: `${props.gutter.right}px`
		}}>
			{props.children}
		</div>
	)
};

export default Slide;
