import dynamic from "next/dynamic";

const LinkCard = dynamic(() => import('../../Cards/LinkCard'));

const FeaturedCardComponent = props => {
	const {block} = props;

	const getPosition = (i) => {
		if(block.data.children.length === 1){
			return 'center'
		} else if(block.data.children.length === 2) {
			if(i === 0){
				return 'left'
			} else  {
				return 'right'
			}
		} else if(block.data.children.length === 3) {
			if(i === 0){
				return 'left'
			} else  {
				return 'right'
			}
		} else if(block.data.children.length === 4) {
			if(i === 0 || i === 2){
				return 'left'
			} else  {
				return 'right'
			}
		}
	}

	return (
		<div className="row featuredCard">
			{block.data.children.map((message, i) => {
				//TODO: MUST SUPPLY URI and pass prop as link!
				return (
					<div key={`featuredCard-${i}`} className={`col s12 m12 l6 ${getPosition(i)}`}>
						<LinkCard link={message.data.link.url} img={message.data.image} title={message.data.title}/>
					</div>
				);
			})}
		</div>
	)
};

export default FeaturedCardComponent;