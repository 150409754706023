// import dynamic from "next/dynamic";
// import Link from "next/link";
import LinkCard from "../../Cards/LinkCard";

// const LazyLoad = dynamic(() => import('../../Image/LazyLoad'));

const MessagesComponent = props => {
	const { block } = props;
	return (
		<div className="row messages">
			{block.data.children.map((message, i) => {
				var messageSplit = 12 / block.data.children.length;
				return (
					<div className={`col s12 m12 l${messageSplit}`} key={`message-${i}`}>
						<LinkCard link={message.data.link} title={message.data.link.text} img={message.data.image} enableTitle={message.data.enableTitle}/>
					</div>
				);
			})}
		</div>
	)
};

export default MessagesComponent;