import dynamic from "next/dynamic";
import Link from "next/link";
import { hasProperty } from "../../../utils/validate";

const LazyLoad = dynamic(() => import('../Image/LazyLoad'));

const LinkCardComponent = props => {
	const { link, title, img, enableTitle} = props;

	return link && (link.type === 'Entry' ?
		<Link href={`/${link.uri}`}>
			<a title={title} className="linkCard">
				<div className="imageHolder">
					<LazyLoad image={img} alt={title} title={title} />
					{enableTitle !== "none" && enableTitle &&
						<div className="content">
							<div className="title">
								<div className="line"></div>
								{title}
							</div>
						</div>
					}
				</div>
			</a>
		</Link>
		:

		<a
			title={title} className="linkCard"
			href={link.url}
			target={`${link.target}`}
		>
			<div className="imageHolder">
				<LazyLoad image={img} alt={title} title={title} />
				{title !== "none" &&  enableTitle &&
					<div className="content">
						<div className="title">
							<div className="line"></div>
							{title}
						</div>
					</div>
				}
			</div>
		</a>


	);
};

export default LinkCardComponent;
