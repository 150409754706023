import Link from "next/link";
import { useEffect, useState } from "react";
import { SiteContext } from "../../../Contexts/SiteContext";

import ProductCard from '../../Cards/ProductCard';
import Slider from '../../Slider/Slider';

const FeaturedProductComponent = props => {
	const { block } = props;
	const [productCategory, setProductCategory] = useState(null);

	useEffect(() => {
		const { data: { children } } = block;
		const addFeatured = children.length > 0 ? children[0].data.product : null;
		const category = addFeatured != null ? addFeatured.uri.split("/")[0] : null;
		setProductCategory(category);
	}, [block]);
	
	const hasChildren = "children" in block.data && block.data.children;

	return (
		<SiteContext.Consumer>{(context) => {
			const { deviceType } = context;
			return (
				<>
					<div className="customContainer">
						<div className="row mt64px featureProductSlider">
							<div className="col s12 center-align" >
								<h4>{block.data.title}</h4>
							</div>
						</div>
						{deviceType == 'desktop' ?
							<div className="row productDesktop">
								{
									hasChildren && block.data.children.map((productData, i) => {
										const productType = productData.data.product.uri.split("/")[0];
										return (
											<div className="col s12 m12 l4" key={`productDesktop-${i}`}>
												<ProductCard productType={productType} slug={productData.data.product.slug} title={productData.data.product.title} img={productData.data.product.featuredImage} description={productData.data.product.description} buttonCopy={productData.data.buttonCopy} url={productData.data.product.url} />
											</div>
										)
									})
								}
							</div>
							:
							<div className="row productMobileSlider">
								<div className="carouselSlider" id="carHomePage">
									<Slider gutter={0} blockSelector="carHomePage" items={1} offset={false} arrowColour="black" autoNext={false} dots={false} dotsLocation="left">
										{hasChildren && block.data.children.map((slide, i) => {
											const productType = slide.data.product.uri.split("/")[0];
											return <ProductCard productType={productType} slug={slide.data.product.slug} key={`productMobile-${i}`} title={slide.data.product.title} img={slide.data.product.featuredImage} description={slide.data.product.description} buttonCopy={slide.data.buttonCopy} url={slide.data.product.url} />
										})}
									</Slider>
								</div>
							</div>
						}
					</div>
					{ productCategory && <div className="row mt64px mb64px showroomWrapper">
						<div className="col s12 center-align">
							<h4>Want to see more?</h4>
							<Link href={`/${productCategory}/showroom`}>
								<a className="showroomLink">
									<h6>VIEW ALL MODELS</h6>
								</a>
							</Link>
						</div>
					</div>}
				</>
			)
		}}</SiteContext.Consumer>
	)
};

export default FeaturedProductComponent;
