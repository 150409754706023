import MessagesComponent from './CategoryBuilder/Messages';
import FeaturedCardComponent from './CategoryBuilder/FeaturedCard';
import FeaturedProductComponent from './CategoryBuilder/FeaturedProduct';
import ShoppingToolsComponent from '../ShoppingTools/ShoppingTools';
import Container from '../../Materialize/Container/Container';

const CategoryBuilder = ({ category = null, data }) => {
	const { builder, ...pageData } = data;

	return builder.map(block => {
		switch (block.component) {
			case "messages":
				return <MessagesComponent block={block} key="messages"/>;
			case "featuredCard":
				return <FeaturedCardComponent block={block} key="featuredCard"/>;
			case "featuredProducts":
				return <FeaturedProductComponent block={block} key="featuredProducts"/>;
			case "shoppingTools":
				return (
					<Container key="shoppingTools" className="catShoppingTools">
						<ShoppingToolsComponent
						 	shoppingTools={block.data.children}
							title={block.title}
							pageData={pageData}
							category={category}					
						/>
					</Container>
				);
		}
	});
};

export default CategoryBuilder;
