import parse from "html-react-parser";
import dynamic from "next/dynamic";
import Link from 'next/link';
import {
	useQueryClient
} from '@tanstack/react-query'

const LazyLoad = dynamic(() => import("../Image/LazyLoad"));

import { getQueryFn } from "../../../utils/api/queryFunction";

const ProductCardComponent = props => {
	const {
		productType,
		title,
		slug,
		heading,
		url,
		img,
		description,
		buttonCopy,
		hasButton = true,
		className
	} = props;

	const queryClient = useQueryClient()

	if (slug) {
		return (
			<Link
				title={`${title}`}
				href={`/${productType}/[slug]`}
				as={`/${productType}/${slug}`}
			>
				<div className={`productCard ${className ? className : ""}`}
					onMouseEnter={async () => {
						await queryClient.prefetchQuery({
							queryKey: [productType, slug],
							queryFn: () => getQueryFn(`${productType}/${slug}`),
							staleTime: 10 * 1000, // only prefetch if older than 10 seconds
						})
					}}
				>
					<div className="productWrapper">
						<div className="productTitle">
							<h2>{title}</h2>
						</div>
						<div className="productImage">
							<LazyLoad image={img} alt={title} blur="product-blur" />
						</div>
					</div>
					{heading && <div className="line" />}
					<div className="productHeading">
						<h2>{heading ? parse(heading) : ""}</h2>
					</div>
					<div className="productDescription">
						{description ? parse(description) : ""}
					</div>
					{hasButton == true && (
						<div className="productButton">
							<button
								className="waves-effect waves-light hButton btn-large buttonRed"
							>
								{buttonCopy}
							</button>
						</div>
					)}
				</div>
			</Link >
		);
	} else {
		return (
			<a title={`${title}`}>
				<div className={`productCard ${className ? className : ""}`}>
					<div className="productWrapper">
						<div className="productTitle">
							<h2>{title}</h2>
						</div>
						<div className="productImage">
							<LazyLoad
								image={img}
								alt={title}
								blur="product-blur"
							/>
						</div>
					</div>
					{heading && <div className="line" />}
					<div className="productHeading">
						<h2>{heading ? parse(heading) : ""}</h2>
					</div>
					<div className="productDescription">
						{description ? parse(description) : ""}
					</div>
				</div>
			</a>
		);
	}
};

export default ProductCardComponent;
