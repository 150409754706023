import { hasProperty } from '../validate';

export const pickDataValuesForButtonsGTM = (category, slug, button) => {
	let dataLayerValues = {};

	dataLayerValues.category = category;
	dataLayerValues.slug = slug;
	dataLayerValues.buttonType = button.type;

	if(button.type === "fal") {
		if (
			hasProperty(button, 'link') &&
		 	hasProperty(button.link, 'type') && 
		 	button.link.type.toLowerCase() === 'entry'
		) {			
			dataLayerValues.buttonTitle = button.link.text;
			dataLayerValues.link = {
				type: button.link.type,
				url: button.link.url,
				uri: button.link.uri
			};
		} else if (
			hasProperty(button, 'link') &&
			hasProperty(button.link, 'type') &&
		 	button.link.type.toLowerCase() === 'url'
		) {
			dataLayerValues.buttonTitle = button.title;
			dataLayerValues.link = {
				type: button.link.type,
				url: button.link.url
			};

			if (hasProperty(button.link, 'uri')) {
				dataLayerValues.link.uri = button.link.uri;
			}
		}
	} else if (
		button.type.toLowerCase() === 'entry' ||
	 	button.type.toLowerCase() === 'button' ||
		button.type.toLowerCase() === 'link' ||
	   	button.type.toLowerCase() === 'recall'
	) {
		if (
			hasProperty(button, 'buttonLink') &&
			hasProperty(button.buttonLink, 'type') &&
			button.buttonLink.type.toLowerCase() == 'entry'
		) {
			dataLayerValues.buttonTitle = button.buttonLink.text;
			dataLayerValues.link = {
				type: button.buttonLink.type,
				url: button.buttonLink.url,
				uri: button.buttonLink.uri
			};
		} else if (
			hasProperty(button, 'link') &&
		 	hasProperty(button.link, 'type') &&
		 	button.link.type.toLowerCase() === 'url'
		) {
			dataLayerValues.buttonTitle = button.title;
			dataLayerValues.link = {
				type: button.link.type,
				url: button.link.url
			};

			if (hasProperty(button.link, 'uri')) {
				dataLayerValues.link.uri = button.link.uri;
			}
		}
	} else if (button.type === 'download' || button.type === "brochure") {		
		let assetType = "brochure";

		if (hasProperty(button, 'title') && button.title.toLowerCase().includes('spec')) {
			assetType = "spec-sheet";
		}

		dataLayerValues.assetType = assetType;

		if (hasProperty(button, 'title')){
			dataLayerValues.buttonTitle = button.title;
		}

		if (hasProperty(button, 'asset') && hasProperty(button.asset, 'url')) {
			dataLayerValues.assetUrl = button.asset.url;
		}

		if (hasProperty(button, 'brochureAsset')) {
			if (hasProperty(button.brochureAsset, 'text')){
				dataLayerValues.buttonTitle = button.brochureAsset.text;
			}

			if (hasProperty(button.brochureAsset, 'url')){
				dataLayerValues.assetUrl = button.brochureAsset.url;
			}
		}
	} else if (button.type === 'form') {
		dataLayerValues.buttonTitle = button.title;
		dataLayerValues.form = {
			handle: button.form.handle,
			name: button.form.name,
		};
	}

	return dataLayerValues;
}
