import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pickDataValuesForButtonsGTM } from "../../../../utils/data.layer.utils/buttons.data.layer.util";
import { addDataLayerEvent } from "../../../../utils/data.layer.utils/data.layer.util";
import { hasProperty } from "../../../../utils/validate";
import Link from "next/link";
import { getCategoryFromUrl } from "./Tool";

const ToolWithDropdown = ({ title, category, tool, pageUrl }) => {
    
	return (
		<div className="toolHolder">
			<div className="tool valign-wrapper">
				{hasProperty(tool, "icon") && tool.icon != null && (
					<FontAwesomeIcon
						icon={[tool.icon.type, tool.icon.icon]}
						fixedWidth
					/>
				)}
				{hasProperty(tool, "links") && (
					<div className="hover">
						{tool.links.map((item, i) => {                            
                            const onClick = () => {
                                addDataLayerEvent('CallToActionEventLinkButton',
                                    {
                                        buttonTitle: `${tool.title} - ${item.text}`,
                                        buttonType: item.type,
                                        link: {type: item.type, url: item.url},
                                        title: title,
                                        url: pageUrl,                                        
                                        category: (!category && "uri" in item && !!item.uri ? getCategoryFromUrl(item.uri) : category)
                                    }
                                );
                            };

							return item.type === 'Entry' ? (
								<Link href={`/${item.uri}`} key={`item${i}`}>
									<a
										className="item"
										title={item.text}
										target={`${item.target}`}
										onClick={onClick}
									>
										{item.text}
									</a>
								</Link>) : (
								<a
									className="item"
									title={item.text}
									href={item.url}
									key={`item${i}`}
                                    onClick={onClick}
									target={`${item.target}`}
								>
									{item.text}
								</a>
							)
						})}
					</div>
				)}
			</div>
			<div className="title">{tool.title}</div>
		</div>
	);
};

export default ToolWithDropdown;