import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import React from 'react';
import { pickDataValuesForButtonsGTM } from '../../../../utils/data.layer.utils/buttons.data.layer.util';
import { addDataLayerEvent } from '../../../../utils/data.layer.utils/data.layer.util';

export const getCategoryFromUrl = (uri) => {
    const splitUrl = `${uri}`.split('/');
    return splitUrl.length > 1 ? splitUrl[0] : null;
}

// category will be null for the Home page when it reimplements category, so keep in mind that it might be empty.
const Tool = ({ category, tool, title, pageUrl }) => {
	const onClick = () => {
        const hasUri = !category && "uri" in tool.link && !!tool.link.uri;

        const trackingVals = {
            ...pickDataValuesForButtonsGTM(category, null, tool),
            title: title,
            url: pageUrl,
            buttonType: tool.link.type,
            category: (hasUri ? getCategoryFromUrl(tool.link.uri) : category)
            // {
            //     buttonTitle: `${tool.title} - ${item.text}`,
            //     buttonType: item.type,
            //     link: {type: item.type, url: item.url},            
            // }
        };

        addDataLayerEvent('CallToActionEventLinkButton', trackingVals);
	};
	
	return (
		<div className="toolHolder">
			{tool.link.type == "Entry" && (
				<Link href={`/${tool.link.uri}`}>
					<a
						title={tool.link.text}
						target={`${tool.link.target}`}
                        onClick={onClick}
                        style={{ cursor: 'pointer' }}
					>
						<div className="tool valign-wrapper">
							<FontAwesomeIcon
								icon={[tool.type, tool.icon]}
								fixedWidth
							/>
						</div>
						<div className="title">{tool.link.text}</div>
					</a>
				</Link>)}
			{tool.link.type != "Entry" && (
				<a
					href={tool.link.url}
					title={tool.link.text}
					target={`${tool.link.target}`}
                    style={{ cursor: 'pointer' }}
                    onClick={onClick}
				>
					<div className="tool valign-wrapper">
						<FontAwesomeIcon
							icon={[tool.type, tool.icon]}
							fixedWidth
						/>
					</div>
					<div className="title">{tool.link.text}</div>
				</a>
			)}
		</div>
	);
};

export default Tool;